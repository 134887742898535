import React, { Fragment, useState, useEffect, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import Countdown from 'react-countdown';

import FirebaseContext from '../../bd/context/firebase/firebaseContext'
import PedidoContext from '../../bd/context/pedido/pedidosContext'

const ResumenPedido = ({ openShoppingCart, setOpenShoppingCart }) => {
    // context de firebase 
    const { firebase } = useContext(FirebaseContext)

    // context de pedido
    const { idPedido, pedido, total, mostrarResumen, eliminarProducto, pedidoRealizado, iniciarPedido } = useContext(PedidoContext)

    // state para el tiempo y el completado
    const [tiempo, setTiempo] = useState(0)
    const [completado, setCompletado] = useState(false)

    // cuando cambia el id del pedido
    useEffect(() => {
        if (idPedido) {
            obtenerProducto();
        }
    }, [idPedido])

    // cuando se actualiza el state del pedido actualizamos el total
    useEffect(() => {
        calcularTotal();
    }, [pedido])

    // calculra el tottal del pedido
    const calcularTotal = () => {
        let nuevoTotal = 0;
        nuevoTotal = pedido.reduce((nuevoTotal, articulo) => nuevoTotal + articulo.total, 0);
        mostrarResumen(nuevoTotal);
    }

    // eliminar un articulo
    const confirmarEliminar = (id) => {
        const eliminar = window.confirm("¿Estás seguro de realizar esta acción?");
        if (eliminar) {
            // eliminar el articulo con el id
            eliminarProducto(id);
            // calcular el nuevo total
            calcularTotal();
        }
    }

    // checkout
    const progresoPedido = async () => {
        const realizarPedido = window.confirm("¿Estás seguro de realizar esta acción? Revisa bien tu pedido");
        if (realizarPedido) {
            // crear un objeto del pedido
            const pedidoObj = {
                tiempoEntrega: 0,
                completado: false,
                total: Number(total),
                orden: pedido,
                creado: Date.now()
            }
            // escribir en firebase el pedido
            try {
                const pedidoFirebase = await firebase.db.collection('ordenes').add(pedidoObj)
                pedidoRealizado(pedidoFirebase.id);
            } catch (error) {
                console.log(error)
            }
        }
    }

    // manejamos el snap del completado y el tiempo
    const obtenerProducto = () => {
        try {
            firebase.db.collection('ordenes')
                .doc(idPedido)
                .onSnapshot(function (doc) {
                    setTiempo(doc.data().tiempoEntrega)
                    setCompletado(doc.data().completado)
                })
        } catch (error) {
            console.log(error)
        }
    }

    // muestra el countdown en la pantalla 
    const renderer = ({ minutes, seconds }) => {

        return (
            <p className='text-6xl font-bold'>{minutes}:{seconds}</p>
        )
    }

    return (
        <>
            <Transition.Root show={openShoppingCart} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpenShoppingCart}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-400"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                        <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                            <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="text-lg font-medium text-gray-900">
                                                        {" "}
                                                        Shopping cart{" "}
                                                    </Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                                            onClick={() => setOpenShoppingCart(false)}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="mt-8">
                                                    <div className="flow-root">
                                                        <ul
                                                            className="-my-6 divide-y divide-gray-200"
                                                        >
                                                            {pedido.length > 0 ? pedido.map((articulo) => (
                                                                <li key={articulo.id} className="flex py-6">
                                                                    <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                                                        <img
                                                                            src={articulo.imagen}
                                                                            alt="img"
                                                                            className="h-full w-full object-cover object-center"
                                                                        />
                                                                    </div>

                                                                    <div className="ml-4 flex flex-1 flex-col">
                                                                        <div>
                                                                            <div className="flex justify-between text-base font-medium text-gray-900">
                                                                                <h3>
                                                                                    <a href="#">
                                                                                        {" "}
                                                                                        {articulo.nombre}{" "}
                                                                                    </a>
                                                                                </h3>
                                                                                <p className="ml-4">{articulo.precio}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex flex-1 items-end justify-between text-sm">
                                                                            <p className="text-gray-500">
                                                                                Cantidad: {articulo.cantidad}
                                                                            </p>

                                                                            <div className="flex">
                                                                                <button
                                                                                    onClick={() => confirmarEliminar(articulo.id)}
                                                                                    type="button"
                                                                                    className="font-medium text-indigo-600 hover:text-indigo-500"
                                                                                >
                                                                                    Remove
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )) : tiempo === 0 && idPedido ? (
                                                                <p>Hemos Recibido tu orden y estamos calculando el tiempo aproximado de preparación</p>
                                                            ) : !completado && idPedido ? (
                                                                <>
                                                                    <p>El tiempo de entrega aproximado es de:</p>
                                                                    <Countdown
                                                                        date={Date.now() + tiempo * 60000}
                                                                        renderer={renderer}
                                                                    />
                                                                </>
                                                            ) : idPedido ? (
                                                                <>
                                                                    <p>Orden lista, puedes pasar a la barra a recoger tu pedido</p>
                                                                    <button
                                                                        type="button"
                                                                        className="w-full flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                                                                        onClick={() => iniciarPedido()}
                                                                    >
                                                                        Iniciar un nuevo pedido
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                <p>No hay articulos</p>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                                                <div className="flex justify-between text-base font-medium text-gray-900">
                                                    <p>Subtotal</p>
                                                    <p>{total}</p>
                                                </div>
                                                <p className="mt-0.5 text-sm text-gray-500">
                                                    Shipping and taxes calculated at checkout.
                                                </p>
                                                <div className="mt-6">
                                                    <button
                                                        type="button"
                                                        className="w-full flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                                                        onClick={() => progresoPedido()}
                                                    >
                                                        Checkout
                                                    </button>
                                                </div>
                                                <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                                                    <p>
                                                        <button
                                                            type="button"
                                                            className="font-medium text-indigo-600 hover:text-indigo-500"
                                                            onClick={() => setOpenShoppingCart(false)}
                                                        >
                                                            Continue Shopping
                                                        </button>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}

export default ResumenPedido;