import React, { Fragment, useState } from 'react'

import ResumenPedido from '../ui/ResumenPedido'
import Navegacion from '../ui/Navegacion'
import Menu from './Menu'

// importar state de context
import FirebaseState from '../../bd/context/firebase/firebaseState'
import PedidoState from '../../bd/context/pedido/pedidosState'

export default function Inicio() {

  const [openShoppingCart, setOpenShoppingCart] = useState(false)

  return (
    <>
      <FirebaseState>
        <PedidoState>
          <Navegacion
            setOpenShoppingCart={setOpenShoppingCart}
          />
          <Menu />
          <ResumenPedido
            openShoppingCart={openShoppingCart}
            setOpenShoppingCart={setOpenShoppingCart}
          />
        </PedidoState>
      </FirebaseState>
    </>
  );
}
