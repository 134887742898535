import React, { useEffect, useContext } from 'react';

import FirebaseContext from '../../bd/context/firebase/firebaseContext';

import Platillo from '../ui/Platillo';

const Menu = () => {
    // context de firebase
    const { menu, obtenerProductos } = useContext(FirebaseContext);

    useEffect(() => {
        obtenerProductos();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="bg-white">
                <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-16 lg:max-w-7xl">
                    <h2 className="sr-only">Products</h2>

                    <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                        {menu.map((platillo) => (
                            <Platillo
                                key={platillo.id}
                                platillo={platillo}
                            />
                        ))}
                    </div>
                </div>
            </div>

        </>
    );
}

export default Menu;