//const firebaseConfig = JSON.parse(process.env.REACT_APP_ACCESO_FIREBASE);
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAEdhDiwtD3o4gk9Sk3Y7kwPdOLU-Cle1k",
    authDomain: "restaurant-87584.firebaseapp.com",
    projectId: "restaurant-87584",
    storageBucket: "restaurant-87584.appspot.com",
    messagingSenderId: "336336262308",
    appId: "1:336336262308:web:9e6e81c7c9c83cf5888231",
    measurementId: "G-DN78PR2D89"
  };

export default firebaseConfig;
