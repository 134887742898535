import React, { useReducer } from "react";

import firebase from '../../firebase';
import FirebaseContext from "./firebaseContext";
import FirebaseReducer from "./firebaseReducer";

import { OBTENER_PRODUCTOS_EXITO } from "../../types";

import _ from "lodash";

const FirebaseState = (props) => {
    // crear state incial
    const initialState = {
        menu: []
    }

    // useReducer con dispatch para ejecutar las funciones
    const [state, dispatch] = useReducer(FirebaseReducer, initialState);

    // funcion para traer los productos
    const obtenerProductos = () => {
        // consultar a la base de datos
        firebase.db
            .collection('productos')
            .where('existencia', '==', true) // traer solo en existencia
            .onSnapshot(manejarSnapshot);

        function manejarSnapshot(snapshot) {
            let platillos = snapshot.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            });

            // ordenar por categoria con lodash 
            platillos = _.sortBy(platillos, 'categoria');

            // tenemos resultados de la base de datos
            dispatch({
                type: OBTENER_PRODUCTOS_EXITO,
                payload: platillos
            });
        }
    }

    return (
        <FirebaseContext.Provider
            value={{
                menu: state.menu,
                firebase,
                obtenerProductos
            }}
        >
            {props.children}
        </FirebaseContext.Provider>
    )
}

export default FirebaseState;