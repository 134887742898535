// state de productos
export const OBTENER_PRODUCTOS_EXITO = 'OBTENER_PRODUCTOS_EXITO' 

// state para el pedido 
export const SELECCIONAR_PRODUCTO = 'SELECCIONAR_PRODUCTO'
export const CONFIRMAR_ORDENAR_PLATILLO = 'CONFIRMAR_ORDENAR_PLATILLO'
export const MOSTRAR_RESUMEN = 'MOSTRAR_RESUMEN'
export const ELIMINAR_PRODUCTO = 'ELIMINAR_PRODUCTO'
export const PEDIDO_ORDENADO = 'PEDIDO_ORDENADO'
export const INICIAR_PEDIDO = 'INICIAR_PEDIDO'
