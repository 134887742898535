import { Fragment } from 'react'
import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, ShoppingCartIcon } from '@heroicons/react/24/outline'

export default function Navegacion({ setOpenShoppingCart }) {
    return (
        <Disclosure as="nav" className="bg-gray-800">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex flex-1 items-center justify-around sm:items-stretch sm:justify-between">
                                <div className="flex flex-shrink-0 items-center">
                                    <h1 className='text-white text-2xl'>Restaurante</h1>
                                </div>
                                <div className="sm:ml-6 sm:block">
                                    <button
                                        type="button"
                                        className="flex font-medium text-white hover:text-indigo-500"
                                        onClick={() => setOpenShoppingCart(true)}
                                    >
                                        <ShoppingCartIcon className="h-6 w-6" aria-hidden="true" />
                                        Pedido
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2">

                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}
