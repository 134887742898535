import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { FirebaseContext } from "../../firebase";

const Sidebar = () => {
  const { usuarioAdmin, firebase, setUsuarioAdmin } =
    useContext(FirebaseContext);

  return (
    <div className="md:w-2/5 xl:w-1/5 bg-gray-800 md:fixed md:min-h-screen">
      <div className="p-6">
        <p className="text-center font-bold uppercase text-white text-2xl tracking-wide">
          RestaurantApp
        </p>
        <button
          onClick={async () => {
            console.log("click");
            await firebase.auth().signOut();
            setUsuarioAdmin({});
          }}
          type="submit"
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cerrar sesion
        </button>
        <p className="mt-3 text-gray-400">
          Administra tu restaurant en las siguintes opciones
        </p>

        <nav className="mt-10">
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "text-yellow-500 p-1 block hover:bg-yellow-500 hover:text-gray-900"
                : "p-1 text-gray-400 block hover:bg-yellow-500 hover:text-gray-900"
            }
            end
            to="/admin/dashboard/"
          >
            Ordenes
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "text-yellow-500 p-1 block hover:bg-yellow-500 hover:text-gray-900"
                : "p-1 text-gray-400 block hover:bg-yellow-500 hover:text-gray-900"
            }
            end
            to="/admin/dashboard/menu"
          >
            Menu
          </NavLink>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
