import { Fragment, useState, useContext, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import PedidoContext from '../../bd/context/pedido/pedidosContext'


const Platillo = ({ platillo }) => {
    // context de pedido
    const { seleccionarPlatillo, guardarPedido } = useContext(PedidoContext);

    // state para el modal
    const [open, setOpen] = useState(false);

    // state para las cantidades
    const [cantidad, setCantidad] = useState(1)
    const [total, setTotal] = useState(0)

    // en cuanto el componente carga calcular la cantidad a pagar
    useEffect(() => {
        calcularTotal()
    }, [cantidad])

    // manejar el modal y el state del platillo al seleccionar
    const handleOpenModal = () => {
        //eliminar algunas propiedades del platillo
        const { existencia, ...platillo2 } = platillo;

        seleccionarPlatillo(platillo2);
        setOpen(true);
    }

    // manejar el cambio de la cantidad
    const handleCantidad = (e) => {
        setCantidad(e.target.value);
    };

    // agregar el platillo/s al carrito
    const agregarCarrito = () => {
        if (cantidad > 0) {
            setOpen(false);

            // almacenar el pedido al pedido principal
            const pedido = {
                ...platillo,
                cantidad,
                total
            }
            guardarPedido(pedido);
        } else {
            alert("No se puede agregar menos de uno");
        }

    }

    // calcula el total del platillo por su cantidad
    const calcularTotal = () => {
        const totalPagar = platillo.precio * cantidad
        setTotal(totalPagar)
    }

    return (
        <>
            <button
                className="group shadow-2xl hover:scale-105"
                onClick={() => handleOpenModal()}
            >
                <div className="group relative">
                    <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none lg:h-80">
                        <img
                            src={platillo.imagen}
                            alt="img"
                            className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                        />
                    </div>
                    <div className="my-4 mx-2 flex justify-between">
                        <h3 className="text-sm text-gray-900">
                            {platillo.nombre}
                        </h3>
                        <p className="text-sm font-medium text-gray-900">${platillo.precio}</p>

                    </div>
                </div>
            </button>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                                enterTo="opacity-100 translate-y-0 md:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 md:scale-100"
                                leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            >
                                <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                                    <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                                        <button
                                            type="button"
                                            className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>

                                        <div className="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8">
                                            <div className="aspect-h-3 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5">
                                                <img src={platillo.imagen} alt="img" className="object-cover object-center" />
                                            </div>
                                            <div className="sm:col-span-8 lg:col-span-7">
                                                <h2 className="text-4xl font-bold text-gray-900 sm:pr-12">{platillo.nombre}</h2>

                                                <div className="my-2 flex flex-col sm:flex-row justify-start">
                                                    <h3 className="text-xl font-bold text-gray-900 sm:mr-1">Precio:</h3>
                                                    <p className="text-lg text-gray-900">${platillo.precio}</p>
                                                </div>

                                                <div className="my-2">
                                                    <h3 className="text-xl font-bold text-gray-900 sm:pr-12">Descripción</h3>
                                                    <p className="text-base text-gray-900 sm:pr-12">{platillo.descripcion}</p>
                                                </div>

                                                <div className="my-2">
                                                    <label className="block font-bold text-xl text-gray-900">Cantidad:</label>
                                                    <input
                                                        type="number"
                                                        value={cantidad}
                                                        onChange={handleCantidad}
                                                        className="mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 block w-full sm:text-sm"
                                                    />
                                                </div>

                                                <button
                                                    type="submit"
                                                    className="mt-6 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    onClick={() => agregarCarrito()}
                                                >
                                                    Agregar al carrito
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default Platillo;