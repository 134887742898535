import React from "react";
import { Routes, Route } from "react-router";
import { Link } from 'react-router-dom'

import Inicio from "./Client/components/paginas/Inicio.js";
import Admin from "./Admin/components/paginas/Admin.js"

const NotFoundPage = () => {
  return (
    <>
      <html class="h-full">
        <body class="h-full">
          <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
              <p className="text-base font-semibold text-indigo-600">404</p>
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page no encontrada</h1>
              <p className="mt-6 text-base leading-7 text-gray-600">Lo sentimos, no encotramos la pagina que estas buscando</p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <Link
                  to="/"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Ir a Restaurante
                </Link>
                <a href="#" className="text-sm font-semibold text-gray-900">
                  Soporte <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          </main>
        </body>
      </html>
    </>
  )
}

function App() {
  return (
    <Routes>
      <Route path="/" element={<Inicio />} />
      <Route path="/admin/*" element={<Admin />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
